import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  theme,
  ChakraProvider,
  ModalFooter,
} from "@chakra-ui/react";
import { useState } from "react";
import { getTimeAfterDay } from "./utils/getTimeAfter";

export function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputValue, setInputValue] = useState<string>("");
  const [shortenedUrl, setShortenedUrl] = useState<ShortenedUrl | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const apiUrl = "https://api.short.io/links/public";
    const apiKey = "pk_HAHXnDyeCeQL9Mjb";

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: apiKey,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          domain: "z.ifmage.xyz",
          originalURL: inputValue.trim(),
          allowDuplicates: false,
        }),
      });

      const data = await response.json();
      setShortenedUrl(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCopyClick = () => {
    if (shortenedUrl) {
      navigator.clipboard.writeText(shortenedUrl.shortURL);
    }
  };

  const handleResetClick = () => {
    setInputValue("");
    setShortenedUrl(null);
  };

  return (
    <ChakraProvider theme={theme}>
      <div className="bg-gray-100 min-h-screen flex justify-center items-center">
        <Container maxW="container.xl">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            py={4}
          >
            <Box
              display="flex"
              background="blue.500"
              alignItems="center"
              color="white"
              px={4}
            >
              <Text fontSize="2xl" fontWeight="bold">
                IfMG
              </Text>
            </Box>
            <Box>
              <Button
                variant="outline"
                colorScheme="gray"
                size="sm"
                onClick={onOpen}
              >
                도움말
              </Button>
            </Box>
          </Box>
        </Container>
        <Box bg="white" rounded="lg" p={8}>
          <Container maxW="container.xl">
            <Box textAlign="center">
              <Text
                fontWeight="bold"
                fontSize={{ base: "2xl", md: "3xl" }}
                mb={4}
              >
                URL 단축하기
              </Text>
              <Text mb={4}>7일 동안 유효한 단축 URL을 생성합니다.</Text>
            </Box>
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormLabel htmlFor="url">
                  URL
                  <Text as="span" color="gray.400" ml={1} fontSize="sm">
                    (http 또는 https 포함)
                  </Text>
                </FormLabel>
                <Box display="flex" justifyContent={"flex-start"}>
                  <Input
                    type="url"
                    id="url"
                    name="url"
                    placeholder="URL을 입력하세요"
                    value={inputValue}
                    onChange={handleInputChange}
                    isDisabled={shortenedUrl !== null}
                  />
                  <Button
                    type="submit"
                    bg="blue.500"
                    color="white"
                    rounded="md"
                    px={4}
                    py={2}
                    ml={4}
                    isDisabled={
                      inputValue.trim() === "" || shortenedUrl !== null
                    }
                    _disabled={{
                      opacity: "0.4",
                      cursor: "not-allowed",
                      pointerEvents: "none",
                    }}
                  >
                    줄이기
                  </Button>
                </Box>
              </FormControl>
            </form>
            {shortenedUrl ? (
              <Box alignItems="center" mt={4}>
                <Text fontWeight="bold">결과:</Text>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  mt={4}
                >
                  <Input
                    type="text"
                    id="shortenedUrl"
                    name="shortenedUrl"
                    value={shortenedUrl.shortURL}
                    isReadOnly
                  />
                  <Text>생성된 링크는 7일 이내로 유효합니다.</Text>
                  <Box display="flex" alignItems="center" mt={4}>
                    <Button
                      onClick={handleCopyClick}
                      ml={4}
                      bg="gray.400"
                      color="white"
                      px={4}
                      py={2}
                      _hover={{ bg: "gray.500" }}
                    >
                      복사하기
                    </Button>
                    <Button
                      onClick={handleResetClick}
                      ml={4}
                      bg="gray.400"
                      color="white"
                      px={4}
                      py={2}
                      _hover={{ bg: "gray.500" }}
                    >
                      다시하기
                    </Button>
                  </Box>
                </Box>
              </Box>
            ) : null}
          </Container>
        </Box>
        <HelpModal isOpen={isOpen} onClose={onClose} />
      </div>
    </ChakraProvider>
  );
}

interface ShortenedUrl {
  secureShortURL: string;
  shortURL: string;
}

interface HelpModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function HelpModal({ isOpen, onClose }: HelpModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>도움말</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>URL를 짧게 만들어주는 도구입니다.</Text>
          <Text>
            URL 입력창에 URL을 입력하신 후 '줄이기' 버튼을 눌러주세요.
            <br />
            7일 동안 유효한 단축 URL이 생성됩니다.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>닫기</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default App;
